.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}





.AccountContainer1  {
  position: relative;
  animation-name: account;
  animation-duration: 2s;  
  animation-fill-mode: forwards;
  animation-delay: inherit;
}

@keyframes account {
  from {top: 30px; opacity: 0%}
  to {top: 0px; }
}





.AccountContainer2 {
  position: relative;
  animation-name: account2;
  animation-duration: 3s;  
  animation-fill-mode: forwards;
  animation-delay: inherit;
}

@keyframes account2 {
  from {top: 30px; opacity: 0%}
  to {top: 0px; }
}


.AccountContainer3 {
  position: relative;
  animation-name: account2;
  animation-duration: 4s;  
  animation-fill-mode: forwards;
  animation-delay: inherit;
}

@keyframes account2 {
  from {top: 30px; opacity: 0%}
  to {top: 0px; }
}

.postjobAnimation {
  position: relative;
  animation-name: account2;
  animation-duration: 3s;  
  animation-fill-mode: forwards;
  animation-delay: inherit;
}

@keyframes account2 {
  from {top: 30px; opacity: 0%}
  to {top: 0px; }
}


.chatAnimation {
  position: relative;
  animation-name: account2;
  animation-duration: 3s;  
  animation-fill-mode: forwards;
  animation-delay: inherit;
}

@keyframes account2 {
  from {top: 30px; opacity: 0%}
  to {top: 0px; }
}

.cantableAnimation {
  position: relative;
  animation-name: account2;
  animation-duration: s;  
  animation-fill-mode: forwards;
  animation-delay: inherit;
}

@keyframes account2 {
  from {top: 30px; opacity: 0%}
  to {top: 0px; }
}


.jobsAnimation {
  position: relative;
  animation-name: account2;
  animation-duration: 3s;  
  animation-fill-mode: forwards;
  animation-delay: inherit;
}

@keyframes account2 {
  from {top: 30px; opacity: 0%}
  to {top: 0px; }
}





.cardsAnimate {
  position: relative;
  animation-name: example;
  animation-duration: 3s;  
  animation-fill-mode: forwards;
}

@keyframes example {
  from {top: 20px; opacity: 5%}
  to {top: 0px; }
}



.jobsActivity {
  position: relative;
  animation-name: example2;
  animation-duration: 3s;  
  animation-fill-mode: forwards;

}

@keyframes example2 {
  from {top: 20px; opacity: 5%}
  to {top: 0px; }
}

.MuiBottomNavigationAction-root :Mui-selected {
  background-color: #efefef !important;
}




/* .MuiGrid-spacing-xs-2 {

  position: relative;
  animation-name: jobactivity;
  animation-duration: 4s;  
  animation-fill-mode: forwards;
}


@keyframes jobactivity {
  from {top: 20px; opacity: 5%}
  to {top: 0px; }
} */
/* 
.MuiTypography-root {


  font-family: Montserrat !important; 
  font-weight: 400 !important;
 
}

.MuiBottomNavigationAction-label {


  font-family: Montserrat !important; 
  font-weight: 400 !important;
 
} */

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
  
}

.custom-upload-Icon {
  content: icon;
  
}
.custom-file-input::before {
  content: 'Upload Logo';
  display: inline-block;
  
  /* background: linear-gradient(top, #f9f9f9, #e3e3e3); */
  background-color: #002448;
  border: 1px solid #ffffff;
  border-radius: 6px;
  padding: 14px 80px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  color: #ffffff;
  cursor: pointer;
  /* text-shadow: 1px 1px #fff; */
  font-weight: 500;
  font-size: 12pt;
  
}
.custom-file-input:hover::before {
  border-color: #002448;
 
}
.custom-file-input:active::before {
  /* background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9); */
  background-color: #002448;
}



.MuiListItemIcon-root {
  margin-left: 33px;
  
}


/* Sidebar menu  */

.makeStyles-iconStyle-176.makeStyles-iconStyle-176 .MuiListItem-button {
  display: block !important;
  text-align: center !important;
  padding-left: 10% !important;
}


.MuiListItem-root {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: flex-start;
  text-decoration: none;
}

.makeStyles-drawerPaper-12 {
  
  width: 180px;
  padding-top: 20px;
  flex-direction: column;
  padding-bottom: 20px;
  background-color: #211f2c;
}


.MuiListItemIcon-root {
  margin-left: 54px !important;
}

.MuiListItem-root {
 
  /* display: flex; */
  display: block !important;
  
}

.MuiListItemText-root {
  margin-left: -10px !important;
}


.FormGroup {
  /* margin: 0 15px 10px; */
  margin: 0 30px 30px 30px;
  
  padding-top: 25px;
  padding-bottom: 25px;
  border-style: none;
  background-color: #fbfbfb;
  will-change: opacity, transform;
  /* box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), */
    /* inset 0 1px 0 #829fff; */
  border-radius: 4px;
  display: block;
}

.FormRow {
  display: -ms-flexbox;
  display: block;
  -ms-flex-align: center;
  align-items: center;
  margin: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  /* border: 1px solid #f28f16; */
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  /* padding: 11px 15px 11px 0;k */
  border-bottom: 1px solid #b5b1b1;
  padding-bottom: 5px;
}

.makeStyles-root-24 {
  margin-bottom: -1%;
}

.MuiTypography-displayBlock {
  text-align: center;
}